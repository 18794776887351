body {
  margin: 0;
  background-color: #222222;
  color: white;
 font-family: "Work Sans", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


.container-logos {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.ens-logo {
  width: auto;
  height: 60px;
}

.ovr-logo {
  height: 60px;
  width: auto;
}

.divider {
  margin: 0 30px;
  font-size: 30px;
  font-weight: 500;
}

.container-domain {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-top: 40px;
  opacity: 0;
  transition: all ease 0.4s;
  transform: translateY(20px);
  cursor: pointer;
}

.domain:hover {
  transform: scale(1.05);
  background-color: white;
  color: #222222;
}




.container-domain.visible {
  opacity: 1!important;
  transform: translateY(0px);
}

.error {
  text-align: center;
  margin-top: 40px;
  color: #ff3356;
  font-size: 18px;
  opacity: 0;
  transform: translateY(20px);
  transition: all ease 0.3s;
}


.error.visible {
  opacity: 1!important;
  transform: translateY(0px);
}


.container-loading {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all ease 0.3s;
  opacity: 1;
  position: fixed;
  left: 0;
  bottom: 0;

}
.container-loading.loaded{
  opacity: 0;
}

.domain {
  font-size: 25px;
  font-weight: 500;
  border: 2px solid white;
  padding: 2px 25px;
  border-radius: 40px;
  display: flex;
  letter-spacing: 1px;
  transition: all ease 0.3s;
}

.domain span {
  padding: 10px;
}

.ens-logo-domain {
  width: 30px;
}


/* SPINNER */
.lds-ellipsis {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.7);

}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@media only screen and (max-width: 600px) {
  .ens-logo {
    height: 30px;
  }
  
  .ovr-logo {
    height: 30px;
  }

  .divider {
    margin: 0 20px;
  }

  .domain {
    font-size: 16px;
    padding: 5px 15px;
    padding-right: 5px;
  }

  .ens-logo-domain {
    width: 20px;
    margin-right: 0px;
  }
}
